<template>
  <svg
    width="700.000000pt"
    height="700.000000pt"
    viewBox="0 0 700.000000 700.000000"
    preserveAspectRatio="xMidYMid meet">
  >
    <metadata>
    Created by potrace 1.11, written by Peter Selinger 2001-2013
    </metadata>
    <g transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)"
    fill="#2196f3" stroke="none">
      <path d="M3327 6414 c-1 -1 -33 -4 -72 -8 -216 -18 -496 -75 -665 -134 -8 -3
      -19 -6 -25 -7 -25 -6 -224 -89 -311 -130 -107 -49 -277 -145 -282 -157 -2 -4
      -7 -8 -12 -8 -27 0 -305 -208 -414 -309 -434 -403 -715 -863 -865 -1416 -26
      -96 -34 -135 -56 -255 -28 -160 -39 -293 -39 -490 0 -128 8 -285 19 -360 124
      -914 637 -1689 1430 -2159 141 -84 479 -237 565 -256 9 -1 54 -15 100 -29 359
      -108 796 -139 1145 -81 111 18 137 23 141 28 2 2 -14 42 -35 89 -51 110 -96
      256 -116 378 -14 91 -13 89 -48 80 -65 -18 -116 -21 -272 -20 -224 1 -290 8
      -481 46 -438 88 -851 311 -1174 634 -263 262 -448 555 -567 895 -24 71 -58
      189 -66 235 -3 14 -8 36 -11 50 -15 65 -33 181 -41 270 -6 72 -7 363 -1 395 2
      11 7 52 11 90 3 39 16 113 27 165 10 52 21 105 24 117 5 28 81 261 93 288 5
      11 17 38 26 60 21 49 102 209 126 250 10 17 26 43 35 59 8 15 38 60 66 100
      268 378 610 655 1023 828 217 92 430 144 695 173 61 7 361 7 400 1 14 -2 51
      -7 84 -11 190 -22 474 -98 632 -170 113 -51 239 -117 298 -156 32 -21 60 -39
      63 -39 2 0 57 -39 120 -86 110 -81 316 -276 396 -374 20 -25 41 -50 46 -56 23
      -28 142 -205 171 -254 116 -196 222 -463 265 -665 18 -81 44 -249 50 -320 11
      -123 6 -383 -8 -452 -8 -35 -11 -63 -8 -63 44 -1 283 -62 338 -86 17 -8 34
      -14 38 -14 4 0 43 -16 86 -35 44 -19 82 -35 84 -35 4 0 7 18 19 90 2 14 7 41
      10 60 13 77 16 98 23 170 13 132 6 403 -13 530 -44 301 -101 514 -200 754
      -176 424 -461 813 -814 1110 -138 116 -299 231 -425 302 -386 217 -746 332
      -1195 380 -56 6 -427 13 -433 8z"/>
      <path d="M3210 4082 l1 -877 426 -255 c234 -140 463 -277 509 -303 l82 -49 74
      78 c40 42 112 107 160 145 l88 67 -238 138 c-130 75 -268 156 -307 179 -38 23
      -132 77 -208 120 -77 43 -142 86 -145 96 -4 10 -7 360 -7 778 l1 760 -218 0
      -218 0 0 -877z"/>
      <path d="M5905 1970 l-510 -510 -291 291 -291 291 -216 -216 c-120 -119 -217
      -218 -217 -221 0 -8 1008 -1015 1017 -1015 4 0 334 327 733 726 l724 725 -219
      219 -220 220 -510 -510z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
